import { createStore } from "vuex";
import auth from "./auth";
import order from "./order";
import registry from "./registry";
import { LocalNotifications } from "@capacitor/local-notifications";

/**
 * Состояние списка заказов
 */
const state = () => ({
  messages: [],
})

const getters = {
  /**
   * функция одной строкой, чтобы потом спарсить из нее версию, редактировать только число внутри ''
   */
  appVersion: Function("return '1.3.9';"),
  getMessages: (state) => {
    return state.messages;
  },
  getSlotsAlert: () => {
    return 'Для перехода к следующему слоту, необходимо завершить предыдущий. В противном случае, оформление будет невозможно!';
  },
}

const mutations = {
  setMessages(state, messages) {
    state.messages = messages;
  }
}

const actions = { 
  logout({dispatch}) {
    dispatch('auth/logout');
    dispatch('order/logout');
  },

  addMessage( { state, commit }, message) {
    let messages = [...state.messages];
    messages.push(message);
    commit('setMessages', messages);
    LocalNotifications.schedule({
        notifications: [
          {
            title: 'Внимание!',
            body: message,
            id: (new Date()).getTime(),
          },
        ]
    });
  },

  clearMessages({ commit }) {
    commit('setMessages', []);
  },
};

export default createStore({
  state,
  getters,
  actions,
  mutations,
  modules: {
    auth,
    order,
    registry
  },
});