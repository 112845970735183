import {$api, setDefaultAxiosHeader} from "@/plugins/Axios";
import { Device } from '@capacitor/device';
import { Preferences as Storage } from '@capacitor/preferences';
import { loginService, positionService } from "../api/auth";
import { WebsocketService } from "@/plugins/WebsocketService";
import { Geolocation } from '@awesome-cordova-plugins/geolocation';
import { LocalNotifications } from "@capacitor/local-notifications";
import endpoints from "@/api/endpoints";
// import {formatDate} from "@/api/helpers";

/**
 * Состояние авторизации
 * deviceId - идентификатор устройства, берем из capacitor/device
 * token - токен авторизации получаем через api по логину и паролю, храним в capacitor/storage
 */
const state = () => ({
  token: null,
  deviceId: null,
  position: null,
  positionTimeout: false,
  isOnWatch: false
})

const getters = {
  token: (state) => {
    return state.token
  },
  deviceId: (state) => {
    return state.deviceId
  },
  position: (state) => {
    return state.position;
  }
}

const mutations = {
  setToken(state, token) {
    state.token = token;
    Storage.set({ key: 'token', value: token });
    setDefaultAxiosHeader("Authorization", token);
  },
  setDeviceId(state, deviceId) {
    state.deviceId = deviceId
    setDefaultAxiosHeader("deviceId", deviceId)
  },
  logout(state) {
    state.token = null;
    Storage.set({ key: 'token', value: null });
    setDefaultAxiosHeader("Authorization", null);
  },
}

const actions = {
  /**
   * Авторизация по логину и паролю
   * @returns 
   */
  async login({ commit, dispatch }, request) {

    try {
      let id = await Device.getId();
      commit('setDeviceId', id.identifier);

      let token = await loginService(request);
      commit('setToken', token);
      dispatch('socketInit');
      dispatch('getPosition');
      dispatch('watchPosition');
      await dispatch('registry/init', {}, {root: true});
      return token;

    } catch(error) {
      if (!error.code) {
        throw {
          'message': 'Не удалось получить идентификатор устройства',
          'code': 500
        };
      }
      throw error;
    }
  },
  /**
   * Инициализация хранилища
   */
  async init({commit, dispatch}) {
    try {
      let id = await Device.getId();
      commit('setDeviceId', id.identifier);

      let token = await Storage.get({key: 'token'});

      if(token && token.value) {
        commit('setToken', token.value);
        let response = await $api.get(endpoints.orders.timestamp);
        console.log(response.data.result);
        if(response.data.result) {
          dispatch('socketInit');
          dispatch('getPosition');
          dispatch('watchPosition');
          dispatch('order/fetchOrders', new Date(response.data.result * 1000), {root: true});
        }
      }

      await LocalNotifications.requestPermissions();
    } catch(error) {
      return false;
    }
    return true;
  },
  /**
   * Запускаем Websocket
   */
  async socketInit( { state, dispatch, rootGetters} ) {
    let response = await $api.get(endpoints.orders.timestamp);
    console.log(response.data.result);
    if(response.data.result) {
      const $websocket = WebsocketService.getInstance();
      $websocket.connect(state.token,
          (event) => {
            if(event && event.data) {
              try {
                let message = JSON.parse(event.data);
                let driverId = rootGetters['order/getDriverId'];
                if(message && message['drivers'] && message.drivers.indexOf(driverId) < 0) {
                  return;
                }
                dispatch('order/fetchOrders', new Date(response.data.result * 1000), {root: true});
                let curOrder = rootGetters['order/getCurrentOrder'];
                if(message && message.orderId && curOrder && curOrder.id == message.orderId) {
                  dispatch('order/fetchOrder', message.orderId, {root: true});
                }
              } catch {
                console.log('Не удалось распарсить сообщение');
              }
            }
          },
          () => {
            /**
             * @todo добавить обработку ошибок
             */
          }
      );
    }
  },
  /**
   * Отслеживание позиции водителя
   */
   async getPosition ({ dispatch }) {
    let geolocation = Geolocation;
    geolocation.getCurrentPosition().then((data) => {
      if (data && data.coords) {
        dispatch('sendPosition', {lat: data.coords.latitude, lon: data.coords.longitude});
      }
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  },
  /**
   * Отслеживание позиции водителя
   */
  async watchPosition ({ dispatch, state }) {
    if (state.isOnWatch) return;
    state.isOnWatch = true;
    let geolocation = Geolocation;
    let watch = geolocation.watchPosition();
    watch.subscribe((data) => {
      if (data && data.coords) {
        dispatch('sendPosition', {lat: data.coords.latitude, lon: data.coords.longitude});
      }
    });
  },
  /**
   * Отправка геопозиции
   * @returns 
   */
   async sendPosition({ state }, request) {
    state.position = request;
    if (state.positionTimeout) {
      clearTimeout(state.positionTimeout);
    }
    state.positionTimeout = setTimeout(() => {
      positionService(request);
    }, 5000);
  },
  /**
   * Выход
   */
  logout({ commit }) {
    commit('logout');
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}